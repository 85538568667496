import { memoize } from "lodash";
import { API_BASE } from "./api.const";

const _get = (path: `/${string}`, body: any) =>
  fetch(`${API_BASE}${path}`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((d) => d.json());

export const get = memoize(_get, (path, body) => {
  return path + JSON.stringify(body);
});
