import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useApp } from "../../app-context";
import { Editor } from "../../pages/EsQueryBuilderPage/Editor";
import * as monaco from "monaco-editor";
import { MarkerSeverity } from "monaco-editor";
import { useGetQueryMatchQuery } from "../../../store/suggestions.api";
import { useMonacoEvent } from "../useMonacoEvent";
import { isNumber } from "lodash";

export const FileEditor: React.FC = function FileEditor() {
  const {
    query,
    codeToQuery,
    setCodeToQuery,
    fileEditorMonacoModel,
    updateSuggestionsFromLocation,
    setIsFileEditorFocused,
    openTabName,
  } = useApp();
  const [codeEditor, setCodeEditor] =
    useState<monaco.editor.IStandaloneCodeEditor>();

  const queryApiInput = useMemo(
    () => ({
      code: codeToQuery,
      query,
    }),
    [codeToQuery, query]
  );

  const { data: matchData } = useGetQueryMatchQuery(queryApiInput);

  useMonacoEvent(
    () => setIsFileEditorFocused(true),
    [setIsFileEditorFocused],
    codeEditor,
    "onDidFocusEditorText"
  );
  useMonacoEvent(
    () => setIsFileEditorFocused(false),
    [setIsFileEditorFocused],
    codeEditor,
    "onDidBlurEditorText"
  );
  useMonacoEvent(
    () => setCodeToQuery(codeEditor.getValue()),
    [setCodeToQuery, codeEditor],
    codeEditor,
    "onDidChangeModelContent"
  );
  useMonacoEvent(
    (pos) =>
      updateSuggestionsFromLocation({
        offset: codeEditor.getModel().getOffsetAt(pos.position),
        code: codeEditor.getValue(),
      }),
    [codeEditor, updateSuggestionsFromLocation],
    codeEditor,
    "onDidChangeCursorPosition"
  );

  useEffect(() => {
    const match = matchData?.match;
    if (!match?.length) {
      if (typeof match?.length === "number") {
        monaco.editor.removeAllMarkers("owner");
      }
      return;
    }
    if (!codeEditor) {
      return;
    }
    const model = codeEditor.getModel();
    if (!model) {
      monaco.editor.removeAllMarkers("owner");
      return;
    }
    const markers = match
      .filter((node) => isNumber(node.start) && isNumber(node.end))
      .map<monaco.editor.IMarkerData>((node) => {
        const start = model.getPositionAt(node.start);
        const end = model.getPositionAt(node.end);
        const marker: monaco.editor.IMarkerData = {
          severity: MarkerSeverity.Error,
          message: node.type,
          startLineNumber: start.lineNumber,
          endLineNumber: end.lineNumber,
          startColumn: start.column,
          endColumn: end.column,
        };
        return marker;
      });
    monaco.editor.setModelMarkers(model, "owner", markers);
  }, [matchData, codeEditor]);

  const editorOptions = useMemo<
    Partial<monaco.editor.IStandaloneEditorConstructionOptions>
  >(
    () =>
      fileEditorMonacoModel
        ? {
            model: fileEditorMonacoModel,
          }
        : {},
    [fileEditorMonacoModel]
  );

  useEffect(() => {
    codeEditor?.layout();
  }, [openTabName, codeEditor]);

  return (
    <div
      className={"source-code-editor"}
      style={{ width: openTabName !== null ? "calc(100% - 430px)" : "100%" }}
    >
      <Editor
        editorOptions={editorOptions}
        onEditorCreated={setCodeEditor}
        height={"calc(100vh - 33px)"}
        language={"typescript"}
      />
    </div>
  );
};
