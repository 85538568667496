import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Editor } from "../../../pages/EsQueryBuilderPage/Editor";
import * as monaco from "monaco-editor";
import { IDisposable, Position } from "monaco-editor";
import { useApp } from "../../../app-context";
import jQuery from "jquery";
import { ActiveListener } from "./ActiveListener";
import { useMonacoEvent } from "../../useMonacoEvent";
import { ESQUERY } from "@governjs/lib";

function useQueryEditorOptionsMemo(suggestionsContainer: HTMLDivElement) {
  return useMemo<
    Partial<monaco.editor.IStandaloneEditorConstructionOptions>
  >(() => {
    return {
      autoClosingBrackets: "always",
      fontSize: 22,
      lineNumbers: "off",
      minimap: { enabled: false },
      language: ESQUERY,
      overflowWidgetsDomNode: suggestionsContainer,
      hideCursorInOverviewRuler: true,
      renderOverviewRuler: false,
      wordBasedSuggestions: false,
      scrollbar: {},
      lineDecorationsWidth: 0,
    };
  }, [suggestionsContainer]);
}

function queryFocusedSuggestionFromDOM() {
  return jQuery(
    "#overflow-location .monaco-list-row.focused .monaco-highlighted-label"
  ).text();
}
const SuggestionsContainer = () => {
  const { setSuggestionsContainer } = useApp();
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const monacoContainer = document.createElement("div");
    monacoContainer.id = "overflow-location";
    monacoContainer.className = "monaco-editor";
    setSuggestionsContainer(monacoContainer);
    try {
      divRef.current?.appendChild(monacoContainer);
    } catch (e) {}

    return () => {
      try {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        divRef.current?.removeChild(monacoContainer);
      } catch (e) {}
    };
  }, [setSuggestionsContainer, divRef]);

  return <div className={"relative z-20"} ref={divRef} />;
};

export const Query: React.FC = function Query() {
  const {
    queryUpdate,
    setQueryUpdate,
    setQuery,
    codeToQuery,
    queryEditor,
    setQueryEditor,
    updateSuggestionsFromAutocomplete,
    suggestionsContainer,
  } = useApp();

  // The value emitted from the editor without usage of editor.model
  // used to rerun the setQuery method
  const [localEditorModel, setLocalEditorModel] =
    useState<monaco.editor.IModel>();
  const [focusedSuggestion, setFocusedSuggestion] = useState("");

  const [cursorPosition, setCursorPosition] = useState<Position>();

  const editorOptions = useQueryEditorOptionsMemo(suggestionsContainer);

  useEffect(() => {
    if (!queryEditor || !queryUpdate) {
      return;
    }
    const localQuery = queryEditor.getValue();
    if (localQuery !== queryUpdate) {
      queryEditor.setValue(
        [localQuery, queryUpdate].filter((s) => !!s).join(" ")
      );
      const endPosition = queryEditor.getModel().getFullModelRange();
      queryEditor.setPosition({
        lineNumber: endPosition.endLineNumber,
        column: endPosition.endColumn,
      });
      setQueryUpdate("");
    }
  }, [queryUpdate, queryEditor, setQueryUpdate]);

  // used to generate a query based on the current position
  useEffect(() => {
    if (
      !localEditorModel ||
      !cursorPosition ||
      localEditorModel?.isDisposed()
    ) {
      return;
    }

    const start = localEditorModel.getValueInRange({
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: cursorPosition.lineNumber,
      endColumn: cursorPosition.column,
    });
    const end = localEditorModel.getValueInRange({
      startLineNumber: 1,
      startColumn: cursorPosition.column,
      endLineNumber: cursorPosition.lineNumber,
      endColumn: Number.MAX_SAFE_INTEGER,
    });
    setQuery(`${start}${focusedSuggestion}${end}`);
  }, [localEditorModel, cursorPosition, focusedSuggestion, setQuery]);

  // used to listen to editor events
  useEffect(() => {
    if (!queryEditor) {
      return;
    }
    const disposables: IDisposable[] = [];
    const openSuggestions = () => {
      queryEditor.trigger(
        "suggestionTriggerName",
        "editor.action.triggerSuggest",
        {}
      );
    };
    const activeListener = new ActiveListener();

    disposables.push(
      queryEditor.onDidChangeCursorPosition((e) => {
        setCursorPosition(e.position);
      }),
      queryEditor.onDidFocusEditorText(() => {
        activeListener.listen(() =>
          setFocusedSuggestion(queryFocusedSuggestionFromDOM())
        );
        openSuggestions();
      }),
      queryEditor.onDidBlurEditorText(() => {
        activeListener.stop();
      }),
      queryEditor.addAction({
        id: "triggerSuggest",
        label: "Trigger suggest",
        keybindings: [monaco.KeyCode.Tab],
        precondition: null,
        keybindingContext: null,
        contextMenuGroupId: "navigation",
        contextMenuOrder: 1.5,
        run: openSuggestions,
      })
    );
    setLocalEditorModel(queryEditor.getModel());
    setCursorPosition(queryEditor.getPosition());

    activeListener.listen(() =>
      setFocusedSuggestion(queryFocusedSuggestionFromDOM())
    );

    return () => {
      activeListener.stop();
      setLocalEditorModel(null);
      setCursorPosition(null);

      disposables.forEach((disposable) => {
        disposable.dispose();
      });
    };
  }, [queryEditor, setQuery, updateSuggestionsFromAutocomplete]);

  const getSuggestions = useCallback(() => {
    const query =
      queryEditor?.getModel()?.getValueInRange({
        startLineNumber: 0,
        startColumn: 0,
        endLineNumber: queryEditor.getPosition().lineNumber,
        endColumn: queryEditor.getPosition().column,
      }) || "";

    if (query && codeToQuery) {
      updateSuggestionsFromAutocomplete({
        code: codeToQuery,
        query,
      });
    }
  }, [codeToQuery, queryEditor, updateSuggestionsFromAutocomplete]);

  useEffect(() => {
    getSuggestions();
  }, [getSuggestions]);

  useMonacoEvent(
    () => {
      getSuggestions();
      setLocalEditorModel(queryEditor.getModel());
      if (queryEditor.hasTextFocus()) {
        queryEditor.trigger("anything", "editor.action.triggerSuggest", null);
      }
    },
    [queryEditor, getSuggestions],
    queryEditor,
    "onDidChangeModelContent"
  );

  return (
    <div>
      <Editor
        height={"33px"}
        editorOptions={editorOptions}
        onEditorCreated={setQueryEditor}
      />
      <SuggestionsContainer />
    </div>
  );
};
