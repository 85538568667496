import * as React from "react";
import { FC } from "react";
import { QueryBuilder } from "../../components/QueryBuilder/QueryBuilder";
import { Sidebar } from "./Sidebar";

export const EsQueryBuilderPage: FC = () => {
  return (
    <div className="dark:bg-zinc-900">
      <div className={"flex"}>
        <Sidebar />
        <div className={"w-full"}>
          <QueryBuilder />
        </div>
      </div>
    </div>
  );
};
