import * as React from "react";
import { useCallback, useState } from "react";

import { AiOutlineCopy, AiOutlineUpload } from "react-icons/ai";

import { useApp } from "../../app-context";
import { useMonacoEvent } from "../useMonacoEvent";
import { List, LIST_ICONS_CLASS, ListIcons, ListItem } from "./List/List";

export const Suggestions: React.FC = function Suggestions() {
  const {
    suggestionsFromLocation,
    setQueryUpdate,
    focusOnQueryEditor,
    queryEditor,
  } = useApp();

  const [isQueryFocused, setIsQueryFocused] = useState(false);

  useMonacoEvent(
    () => {
      setIsQueryFocused(true);
    },
    [],
    queryEditor,
    "onDidFocusEditorText"
  );
  useMonacoEvent(
    () => {
      setIsQueryFocused(false);
    },
    [],
    queryEditor,
    "onDidBlurEditorText"
  );

  const applySuggestion = useCallback(
    (suggestion: string) => {
      setQueryUpdate(suggestion);
    },
    [setQueryUpdate]
  );

  return (
    <div className={"relative h-1 w-[430px]"}>
      {!isQueryFocused && suggestionsFromLocation?.data?.suggestions?.length ? (
        <List>
          {suggestionsFromLocation?.data?.suggestions?.map((suggestion) => (
            <ListItem key={suggestion}>
              {/* TODO replace tooltip */}
              <ListIcons>
                <AiOutlineCopy
                  className={`${LIST_ICONS_CLASS} pr-1`}
                  onClick={() => {
                    navigator.clipboard.writeText(suggestion);
                  }}
                />
                <AiOutlineUpload
                  className={`${LIST_ICONS_CLASS} pl-1`}
                  onClick={() => {
                    applySuggestion(suggestion);
                  }}
                />
              </ListIcons>
              <pre>{suggestion}</pre>
            </ListItem>
          ))}
        </List>
      ) : (
        <div
          onClick={focusOnQueryEditor}
          className={
            "flex items-center justify-center dark:text-slate-300 text-lg font-semibold text-center cursor-pointer relative select-none p-4 z-10"
          }
          style={{ height: "calc(100vh - 33px)" }}
        >
          Start editing your query or click code to see suggestions.
        </div>
      )}
    </div>
  );
};
