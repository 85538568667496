import blurCyanImage from "./images/blur-cyan.png";
import { Button } from "./Button";
import { HeroBackground } from "./HeroBackground";
import blurIndigoImage from "./images/blur-indigo.png";
import { TrafficLightsIcon } from "./TrafficLightsIcon";
import clsx from "clsx";
import * as React from "react";
import { useApp } from "../../app-context";
import { Editor } from "../EsQueryBuilderPage/Editor";
import { useEffect, useMemo, useState } from "react";
import * as monaco from "monaco-editor";
import { MarkerSeverity } from "monaco-editor";
import { demoContent } from "../../const";

const tabs = [
  { name: "no-underscore-example.js", isActive: true },
  { name: "package.json", isActive: false },
];

const EditorModel = monaco.editor.createModel(
  "",
  "typescript",
  monaco.Uri.file("landing.tsx")
);

export function Hero() {
  const { authUsingGithub, authUsingEmail } = useApp();
  const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor>();

  const options = useMemo<monaco.editor.IStandaloneEditorConstructionOptions>(
    () => ({
      theme: "vs-dark",
      lineDecorationsWidth: 0,
      model: EditorModel,
    }),
    []
  );

  useEffect(() => {
    if (editor) {
      const model = editor.getModel();
      monaco.editor.setModelMarkers(model, "demo-user", [
        {
          severity: MarkerSeverity.Error,
          message: "Using variables prefixed by _ is not allowed",
          startLineNumber: 3,
          endLineNumber: 3,
          startColumn: 3,
          endColumn: 12,
        },
      ]);
      // editor.setModelMarkers();
    }
  }, [editor]);

  return (
    <div className="overflow-hidden bg-slate-900 dark:-mb-32 dark:mt-[-4.5rem] dark:pb-32 dark:pt-[4.5rem] dark:lg:mt-[-4.75rem] dark:lg:pt-[4.75rem] min-h-screen">
      <div className="py-16 sm:px-2 lg:relative lg:py-20 lg:px-0">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 px-4 lg:max-w-8xl lg:grid-cols-2 lg:px-8 xl:gap-x-16 xl:px-12 lg:max-w-8xl">
          <div className="relative z-10 md:text-center lg:text-left">
            <img
              alt={""}
              className="absolute bottom-full right-full -mr-72 -mb-56 opacity-50"
              src={blurCyanImage}
            />
            <div className="relative">
              <p className="inline bg-gradient-to-r from-indigo-200 via-sky-400 to-indigo-200 bg-clip-text font-display text-5xl tracking-tight text-transparent">
                Make a custom eslint rule in 2 minutes.
              </p>
              <p className="mt-3 text-2xl tracking-tight text-slate-400">
                First type css-like syntax with autocompletion to query patterns
                you disallow.
                <br />
                And then never write the same comment when reviewing code again.
              </p>
              <div className="mt-8 flex gap-4 md:justify-center lg:justify-start">
                <Button onClick={authUsingGithub}>
                  Get started with Github
                </Button>
                {process.env.NODE_ENV === "development" && (
                  <Button variant={"secondary"} onClick={authUsingEmail}>
                    Email login
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="relative lg:static xl:pl-10">
            <div className="absolute inset-x-[-50vw] -top-32 -bottom-48 [mask-image:linear-gradient(transparent,white,white)] dark:[mask-image:linear-gradient(transparent,white,transparent)] lg:left-[calc(50%+14rem)] lg:right-0 lg:-top-32 lg:-bottom-32 lg:[mask-image:none] lg:dark:[mask-image:linear-gradient(white,white,transparent)]">
              <HeroBackground className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 lg:left-0 lg:translate-x-0 lg:translate-y-[-60%]" />
            </div>
            <div className="relative">
              <img
                alt={""}
                className="absolute -top-64 -right-64"
                src={blurCyanImage}
                width={530}
                height={530}
              />
              <img
                alt={""}
                className="absolute -bottom-40 -right-44"
                src={blurIndigoImage}
                width={567}
                height={567}
              />
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-tr from-sky-300 via-sky-300/70 to-blue-300 opacity-10 blur-lg" />
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-tr from-sky-300 via-sky-300/70 to-blue-300 opacity-10" />
              <div className="relative rounded-2xl bg-vs-dark-bg ring-1 ring-white/10 backdrop-blur overflow-hidden">
                <div className="absolute -top-px left-20 right-11 h-px bg-gradient-to-r from-sky-300/0 via-sky-300/70 to-sky-300/0" />
                <div className="absolute -bottom-px left-11 right-20 h-px bg-gradient-to-r from-blue-400/0 via-blue-400 to-blue-400/0" />
                <div className="pt-4">
                  <TrafficLightsIcon className="pl-4 h-2.5 w-auto stroke-slate-500/30" />
                  <div className="pl-4 pb-4 mt-4 flex space-x-2 text-xs">
                    {tabs.map((tab) => (
                      <div
                        key={tab.name}
                        className={clsx(
                          "flex h-6 rounded-full",
                          tab.isActive
                            ? "bg-gradient-to-r from-sky-400/30 via-sky-400 to-sky-400/30 p-px font-medium text-sky-300"
                            : "text-slate-500"
                        )}
                      >
                        <div
                          className={clsx(
                            "flex items-center rounded-full px-2.5",
                            tab.isActive && "bg-slate-800"
                          )}
                        >
                          {tab.name}
                        </div>
                      </div>
                    ))}
                  </div>
                  <Editor
                    editorOptions={options}
                    height={"180px"}
                    language={"typescript"}
                    value={demoContent.code}
                    onEditorCreated={setEditor}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
