import { useCallback, useEffect, useState } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { demoContent } from "../const";
import * as monaco from "monaco-editor";
import {
  useLazyGetSuggestionsFromAutocompleteQuery,
  useLazyGetSuggestionsFromLocationQuery,
} from "../../store/suggestions.api";
import { MiniStore } from "../mini-store";

const EditorModel = monaco.editor.createModel(
  "",
  "typescript",
  monaco.Uri.file("asdfasdfasfd.tsx")
);

export function useQueryBuilder() {
  const [codeToQuery, setCodeToQuery] = useLocalStorage(
    "codeToQuery",
    demoContent.code
  );
  const [initialCodeToQuery] = useLocalStorage("codeToQuery", demoContent.code);
  const [query, setQuery] = useLocalStorage("query", demoContent.query);
  const [initialQuery] = useLocalStorage("initial-query", query);
  const [queryUpdate, setQueryUpdate] = useState("");
  const [isFileEditorFocused, setIsFileEditorFocused] = useState(false);
  const [queryEditor, setQueryEditor] =
    useState<monaco.editor.IStandaloneCodeEditor>();

  const [updateSuggestionsFromLocation, suggestionsFromLocation] =
    useLazyGetSuggestionsFromLocationQuery();
  const [updateSuggestionsFromAutocomplete, suggestionsFromAutocomplete] =
    useLazyGetSuggestionsFromAutocompleteQuery();

  const [suggestionsContainer, setSuggestionsContainer] = useState(null);

  const focusOnQueryEditor = useCallback(() => {
    if (queryEditor) {
      queryEditor.focus();
    } else {
      console.error(
        "Tried to focus on query editor without an editor instance"
      );
    }
  }, [queryEditor]);

  useEffect(() => {
    MiniStore.currentCode = codeToQuery;
  }, [codeToQuery]);

  return {
    codeToQuery,
    setCodeToQuery,
    query,
    setQuery,
    initialQuery,
    queryUpdate,
    setQueryUpdate,
    isFileEditorFocused,
    setIsFileEditorFocused,
    queryEditor,
    setQueryEditor,
    updateSuggestionsFromLocation,
    suggestionsFromLocation,
    updateSuggestionsFromAutocomplete,
    suggestionsFromAutocomplete,
    suggestionsContainer,
    setSuggestionsContainer,
    focusOnQueryEditor,
    fileEditorMonacoModel: EditorModel,
    initialCodeToQuery,
  };
}
