import * as React from "react";
import { useContext } from "react";
import { useAppState } from "./App";
import * as monaco from "monaco-editor";
import { SidebarTabs } from "./app-setup-hooks/useTabs";

const appContext = React.createContext<ReturnType<typeof useAppState>>({
  setIsAuthLoading(value: ((prevState: boolean) => boolean) | boolean): void {},
  supabaseClient: undefined,
  isSuggestionsTabOpen: false,
  openTabName: undefined,
  setOpenTabName(
    value: ((prevState: SidebarTabs) => SidebarTabs) | SidebarTabs
  ): void {},
  toggleSuggestionsTab(): void {},
  fileEditorMonacoModel: undefined,
  initialQuery: undefined,
  authUsingEmail(): Promise<void> {
    return Promise.resolve(undefined);
  },
  focusOnQueryEditor(): void {},
  isFileEditorFocused: false,
  deleteQuery(id: string): Promise<void> {
    return Promise.resolve(undefined);
  },
  fetchQueries(): void {},
  initialCodeToQuery: undefined,
  isQueriesTabOpen: false,
  queries: [],
  saveQuery(props: { name?: string; id?: string } | undefined): Promise<void> {
    return Promise.resolve(undefined);
  },
  selectedQuery: undefined,
  toggleQueriesTab(): void {},
  queryEditor: undefined,
  setIsFileEditorFocused(
    value: ((prevState: boolean) => boolean) | boolean
  ): void {},
  setQueryEditor(
    value:
      | ((
          prevState: monaco.editor.IStandaloneCodeEditor
        ) => monaco.editor.IStandaloneCodeEditor)
      | monaco.editor.IStandaloneCodeEditor
  ): void {},
  suggestionsFromAutocomplete: undefined,
  suggestionsFromLocation: undefined,
  updateSuggestionsFromAutocomplete: undefined,
  updateSuggestionsFromLocation: undefined,
  queryUpdate: "",
  setQueryUpdate(value: ((prevState: string) => string) | string): void {},
  // functions
  authUsingGithub(): Promise<void> {
    return Promise.resolve(undefined);
  },
  logout: null,
  // values
  isAuthLoading: false,
  codeToQuery: "",
  query: "",
  theme: "",
  suggestionsContainer: null,
  user: null,
  // setters
  setTheme: null,
  setQuery: null,
  setCodeToQuery: null,
  setSuggestionsContainer: null,
  setUser: null,
});

export const useApp = () => useContext(appContext);

export const AppProvider = appContext.Provider;
