import { configureStore } from "@reduxjs/toolkit";
import { suggestionsSlice } from "./suggestionsSlice";
import * as suggestions from "./suggestions.api";

export const store = configureStore({
  reducer: {
    suggestions: suggestionsSlice.reducer,
    [suggestions.reducerPath]: suggestions.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(suggestions.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
