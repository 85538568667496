import { ImExit } from "react-icons/im";
import { AiOutlineHome } from "react-icons/ai";
import { BiReset, BiSave } from "react-icons/bi";
import {
  MdOutlineFindInPage,
  MdRule,
  MdOutlineAddToPhotos,
} from "react-icons/md";

import { Tooltip } from "react-tooltip";
import * as React from "react";
import { useCallback } from "react";
import { useApp } from "../../app-context";
import { useNavigate } from "react-router-dom";
import { localStoreKeys } from "../../hooks/useLocalStorage";
import clsx from "clsx";
import { SidebarTabs } from "../../app-setup-hooks/useTabs";

export const Sidebar = () => {
  const {
    saveQuery,
    logout,
    user,
    isSuggestionsTabOpen,
    toggleSuggestionsTab,
    isQueriesTabOpen,
    toggleQueriesTab,
    setHoveredTabName,
    newQuery,
  } = useApp();
  const navigate = useNavigate();

  const resetLocalStore = useCallback(() => {
    localStoreKeys.forEach((key) => {
      window.localStorage.removeItem(key);
    });
    window.location.reload();
  }, []);
  return (
    <div
      className={
        "bg-zinc-800 flex items-center py-4 relative flex-col justify-between hover:z-40"
      }
    >
      {user && (
        <>
          <ImExit
            onClick={logout}
            id="exit-icon"
            data-tooltip-content="Logout"
            className={
              "dark:text-zinc-400 h-5 w-5 cursor-pointer p-2 box-content hover:bg-zinc-900"
            }
          />
          <Tooltip anchorId="exit-icon" place={"right"} />
        </>
      )}
      {!user && (
        <>
          <AiOutlineHome
            onClick={() => navigate("/")}
            id="home-icon"
            data-tooltip-content="Home"
            className={
              "dark:text-zinc-400 h-5 w-5 cursor-pointer p-2 box-content hover:bg-zinc-900"
            }
          />{" "}
          <Tooltip anchorId="home-icon" place={"right"} />
        </>
      )}
      <MdOutlineFindInPage
        id="show-list-icon"
        data-tooltip-content="Show suggestions from text"
        onClick={toggleSuggestionsTab}
        className={clsx(
          "h-5 w-5 cursor-pointer p-2 box-content hover:bg-zinc-900",
          isSuggestionsTabOpen ? "dark:text-white" : "dark:text-zinc-400"
        )}
      />
      <MdRule
        id="saved-queries-icon"
        data-tooltip-content="Show saved queries"
        onClick={toggleQueriesTab}
        className={clsx(
          "h-5 w-5 cursor-pointer p-2 box-content hover:bg-zinc-900",
          isQueriesTabOpen ? "dark:text-white" : "dark:text-zinc-400"
        )}
        onMouseEnter={() => {
          setHoveredTabName(SidebarTabs.queries);
        }}
        onMouseLeave={() => {
          setHoveredTabName(null);
        }}
      />

      <div className={"mb-auto"} />

      <MdOutlineAddToPhotos
        onClick={() => newQuery()}
        id="new-rule-icon"
        data-tooltip-content="New rule"
        className={
          "dark:text-zinc-400 h-5 w-5 cursor-pointer p-2 box-content hover:bg-zinc-900"
        }
      />
      <BiSave
        onClick={() => saveQuery()}
        id="save-query-icon"
        data-tooltip-content="Save query"
        className={
          "dark:text-zinc-400 h-5 w-5 cursor-pointer p-2 box-content hover:bg-zinc-900"
        }
      />
      <BiReset
        onClick={resetLocalStore}
        id="reset-localStore-icon"
        data-tooltip-content="Reset localStore"
        className={
          "dark:text-zinc-400 h-5 w-5 cursor-pointer p-2 box-content hover:bg-zinc-900"
        }
      />
      <Tooltip anchorId="new-rule-icon" place={"right"} />
      <Tooltip anchorId="save-query-icon" place={"right"} />
      <Tooltip anchorId="saved-queries-icon" place={"right"} />
      <Tooltip anchorId="show-list-icon" place={"right"} />
      <Tooltip anchorId="reset-localStore-icon" place={"right"} />
    </div>
  );
};
