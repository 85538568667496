import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import { useApp } from "../../app-context";
import { List, LIST_ICONS_CLASS, ListIcons, ListItem } from "./List/List";
import { useNavigate, useParams } from "react-router-dom";

const QueryListItem: React.FC<{ query: any }> = ({ query }) => {
  const [value, setValue] = useState<string>();
  const { queryId } = useParams();
  const navigate = useNavigate();
  const { deleteQuery, saveQuery } = useApp();
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const inputRef = useRef();

  const save = useCallback(async () => {
    await saveQuery({
      name: value,
      id: query.id,
    });
  }, [value, query.id, saveQuery]);

  useEffect(() => {
    const onWindowClick = (e) => {
      if (isEditModeOn && e.target !== inputRef.current) {
        setIsEditModeOn(false);
        save();
      }
    };
    window.addEventListener("click", onWindowClick);
    return () => window.removeEventListener("click", onWindowClick);
  }, [isEditModeOn, save]);

  return (
    <ListItem key={query.id}>
      <ListIcons>
        <AiOutlineDelete
          onClick={() => deleteQuery(query.id)}
          className={`${LIST_ICONS_CLASS} pr-1`}
        />
        <AiOutlineEdit
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsEditModeOn(!isEditModeOn);
          }}
          className={`${LIST_ICONS_CLASS} pl-1`}
        />
      </ListIcons>
      <div
        className={`relative w-full noselect cursor-pointer ${
          queryId === query.id && " text-white font-semibold"
        }`}
      >
        <div
          onClick={() => {
            navigate(`/esquery-editor/${query.id}`);
          }}
          className={`select-none`}
        >
          {query.name}
        </div>
        {isEditModeOn && (
          <input
            onKeyDown={async (e) => {
              setValue((e.target as any).value);
              if (e.key === "Enter") {
                await saveQuery({
                  name: (e.target as any).value,
                  id: query.id,
                });
                (e.target as any).blur();
                setIsEditModeOn(false);
              }
            }}
            ref={inputRef}
            className={
              "left-0 right-0 absolute dark:text-white dark:bg-zinc-900"
            }
            type="text"
            autoFocus={true}
            defaultValue={query.name || "Add you vaule here"}
          />
        )}
      </div>
    </ListItem>
  );
};

export const Queries: React.FC = function Suggestions() {
  const { queries } = useApp();

  return (
    queries && (
      <List>
        {queries?.map((q) => (
          <QueryListItem key={q.id} query={q} />
        ))}
      </List>
    )
  );
};
