import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { App } from "./app/App";
import "./init-monaco";
import "./style.css";
import { store } from "./store/store";
import { NotAuthedPage } from "./app/pages/NotAuthedPage/NotAuthedPage";
import { EsQueryBuilderPage } from "./app/pages/EsQueryBuilderPage/EsQueryBuilderPage";
import "./theme-manager";
import { Auth } from "./app/pages/Auth/Auth";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <NotAuthedPage />,
      },
      {
        path: "esquery-editor",
        element: <EsQueryBuilderPage />,
      },
      {
        path: "esquery-editor/:queryId",
        element: <EsQueryBuilderPage />,
      },
      {
        path: "demo",
        element: <EsQueryBuilderPage />,
      },
      {
        path: "auth",
        element: <Auth />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
