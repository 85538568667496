import * as monaco from "monaco-editor";
import { ESQUERY, tokenizer } from "@governjs/lib";
import { MiniStore } from "./app/mini-store";
import { get } from "./get";

monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
  noSemanticValidation: true,
});

monaco.languages.register({
  id: ESQUERY,
});
// TODO choose colors https://www.materialpalette.com/grey/indigo
monaco.editor.defineTheme(ESQUERY, {
  base: "vs", // can also be vs-dark or hc-black
  inherit: true, // can also be false to completely replace the builtin rules
  rules: [
    {
      token: "attribute.name.esquery",
      foreground: "#512DA8",
      fontStyle: "italic",
    },
    {
      token: "testing",
      foreground: "#8BC34A",
      background: "#512DA8",
      fontStyle: "italic underline",
    },
  ],
  colors: {
    "editor.foreground": "#000000",
  },
});
monaco.languages.setLanguageConfiguration(ESQUERY, {
  surroundingPairs: [
    { open: "{", close: "}" },
    { open: "[", close: "]" },
    { open: "(", close: ")" },
    { open: "<", close: ">" },
    { open: "'", close: "'" },
    { open: '"', close: '"' },
  ],
  autoClosingPairs: [
    { open: "{", close: "}" },
    { open: "[", close: "]" },
    { open: "(", close: ")" },
    { open: "'", close: "'", notIn: ["string", "comment"] },
    { open: '"', close: '"', notIn: ["string", "comment"] },
  ],
});
monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
  jsx: monaco.languages.typescript.JsxEmit.ReactJSX,
});
monaco.languages.registerCompletionItemProvider(ESQUERY, {
  // Not in use since we use trigger editor.action.triggerSuggest onDidChangeModelContent
  triggerCharacters: [],
  provideCompletionItems: async function (model, position) {
    const code = MiniStore.currentCode;
    const query = model.getValueInRange({
      startLineNumber: 0,
      startColumn: 0,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    });

    const { suggestions } = await get("/GET autocomplete", {
      code,
      query,
    });
    if (!suggestions) {
      return { suggestions: [] };
    }
    return {
      suggestions: suggestions.map((suggestion) => {
        return {
          label: suggestion,
          kind: monaco.languages.CompletionItemKind.Function,
          // documentation: "Describe your library here",
          insertText: suggestion,
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        } as monaco.languages.CompletionItem;
      }),
    };
  },
});
monaco.languages.setMonarchTokensProvider(ESQUERY, tokenizer);
