import * as React from "react";
import { Query } from "./Query/Query";
import { FileEditor } from "./FileEditor";
import { Suggestions } from "./Suggestions";
import { useApp } from "../../app-context";
import { Queries } from "./Queries";
import { useMonacoEvent } from "../useMonacoEvent";
import { useMemo, useState } from "react";

export const QueryBuilder: React.FC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const {
    isSuggestionsTabOpen,
    isQueriesTabOpen,
    queryEditor,
    hoveredTabName,
  } = useApp();

  useMonacoEvent(
    () => {
      setIsFocused(true);
    },
    [],
    queryEditor,
    "onDidFocusEditorText"
  );
  useMonacoEvent(
    () => {
      setIsFocused(false);
    },
    [],
    queryEditor,
    "onDidBlurEditorText"
  );

  const width = useMemo(() => {
    if (hoveredTabName) {
      return "w-[430px]";
    }
    return "w-0";
  }, [hoveredTabName]);

  return (
    <>
      <div className={`relative ${isFocused ? " z-20" : ""}`}>
        <Query />
      </div>
      <div className={`flex relative ${isFocused ? " z-10" : ""}`}>
        {isSuggestionsTabOpen && <Suggestions />}
        <div
          className={`${
            isQueriesTabOpen
              ? "relative"
              : `absolute bg-zinc-900 z-30 h-full hover:w-[430px] ${width}`
          } w-[430px] overflow-auto`}
        >
          <Queries />
        </div>
        <FileEditor />
      </div>
    </>
  );
};
