import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Node } from "estree";
import { API_BASE } from "../api.const";

interface QueryRequest {
  query: string;
  code: string;
}

const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE }),
  endpoints: (build) => ({
    // TODO move autocomplete outside of monaco
    getSuggestionsFromAutocomplete: build.query<
      { suggestions: string[] },
      QueryRequest
    >({
      query: (body) => ({
        url: "GET autocomplete",
        method: "post",
        body,
      }),
    }),
    getQueryMatch: build.query<
      { match: (Node & { start: number; end: number })[] },
      QueryRequest
    >({
      query: (body) => ({
        url: "GET query",
        method: "post",
        body,
      }),
    }),
    getSuggestionsFromLocation: build.query<
      { suggestions: string[] },
      { offset: number; code: string }
    >({
      query: (body) => ({
        url: "GET suggestion-for-location",
        method: "post",
        body,
      }),
    }),
  }),
});

// Auto-generated hooks
export const {
  useLazyGetSuggestionsFromAutocompleteQuery,
  useGetQueryMatchQuery,
  useLazyGetSuggestionsFromLocationQuery,
} = api;

// Possible exports
export const { endpoints, reducerPath, reducer, middleware } = api;
// reducerPath, reducer, middleware are only used in store configuration
// endpoints will have:
// endpoints.getPosts.initiate(), endpoints.getPosts.select(), endpoints.getPosts.useQuery()
// endpoints.addPost.initiate(), endpoints.addPost.select(), endpoints.addPost.useMutation()
// see `createApi` overview for _all exports_
