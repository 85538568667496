import { useCallback, useMemo, useState } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

export enum SidebarTabs {
  suggestions = "suggestions",
  queries = "queries",
}

export function useTabs() {
  const [hoveredTabName, setHoveredTabName] = useState<SidebarTabs>(null);

  const [openTabName, setOpenTabName] = useLocalStorage(
    "open-tab-name",
    SidebarTabs.suggestions
  );

  const isSuggestionsTabOpen = useMemo(
    () => openTabName === SidebarTabs.suggestions,
    [openTabName]
  );

  const isQueriesTabOpen = useMemo(
    () => openTabName === SidebarTabs.queries,
    [openTabName]
  );

  const toggleTabByName = useCallback(
    (tab: SidebarTabs) => {
      if (openTabName === tab) {
        setOpenTabName(null);
      } else {
        setOpenTabName(tab);
      }
    },
    [openTabName, setOpenTabName]
  );

  const toggleSuggestionsTab = useCallback(() => {
    toggleTabByName(SidebarTabs.suggestions);
  }, [toggleTabByName]);

  const toggleQueriesTab = useCallback(() => {
    toggleTabByName(SidebarTabs.queries);
  }, [toggleTabByName]);

  return {
    openTabName,
    setOpenTabName,
    isSuggestionsTabOpen,
    toggleSuggestionsTab,
    toggleQueriesTab,
    isQueriesTabOpen,
    hoveredTabName,
    setHoveredTabName,
  };
}
