import * as React from "react";
import { useEffect, useRef, useState } from "react";
import * as monaco from "monaco-editor";
import { useApp } from "../../app-context";

interface EditorProps {
  value?: string;
  height: string;
  language?: string;
  editorOptions?: Partial<monaco.editor.IStandaloneEditorConstructionOptions>;
  onEditorCreated?: (editor: monaco.editor.IStandaloneCodeEditor) => unknown;
}

export const Editor: React.FC<EditorProps> = function Editor(props) {
  const { language, editorOptions, onEditorCreated } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [editor, setEditor] =
    useState<ReturnType<typeof monaco.editor.create>>();

  const { theme } = useApp();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const localEditor = monaco.editor.create(ref.current, {
      fontSize: 18,
      language: language,
      hover: {
        enabled: true,
      },
      theme,
      ...(editorOptions || {}),
    });

    setEditor(localEditor);

    setTimeout(() => {
      if (onEditorCreated instanceof Function) {
        onEditorCreated(localEditor);
      }
    }, 100);

    return () => {
      setEditor(undefined);
      localEditor.dispose();
    };
  }, [language, editorOptions, onEditorCreated, theme]);

  useEffect(() => {
    if (editor?.getValue() === props.value) {
      return;
    }
    if (props.value) {
      editor?.setValue(props.value || "");
    }
  }, [props.value, editor]);

  useEffect(() => {
    editor?.layout();
  }, [props.height, editor]);

  useEffect(() => {
    const onResize = () => {
      editor.layout();
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [editor]);

  return (
    <div className="drop-shadow">
      <div ref={ref} style={{ height: props.height }} />
    </div>
  );
};
