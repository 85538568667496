export class ActiveListener {
  isListening = false;
  cb: (...args: unknown[]) => unknown;

  listen(cb) {
    this.cb = cb;
    this.isListening = true;
    this.tick();
  }

  stop() {
    this.isListening = false;
  }

  tick() {
    if (!this.isListening) {
      return;
    }
    this.cb();
    setTimeout(() => this.tick(), 100);
  }
}
