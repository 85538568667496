import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "./useAuth";
import { useQueryBuilder } from "./useQueryBuilder";
import { useNavigate, useParams } from "react-router-dom";

interface useAccountStoreProps {
  auth: ReturnType<typeof useAuth>;
  queryBuilder: ReturnType<typeof useQueryBuilder>;
}

export function useAccountStore({ auth, queryBuilder }: useAccountStoreProps) {
  const [queries, setQueries] = useState<any[]>();
  // const [selectedQuery, setSelectedQuery] = useState<any>();
  const { queryId } = useParams();

  const selectedQuery = useMemo(
    () => queries?.find((q) => q.id === queryId),
    [queries, queryId]
  );

  useEffect(() => {
    queryBuilder?.fileEditorMonacoModel?.setValue(selectedQuery?.snippet || "");
  }, [queryBuilder?.fileEditorMonacoModel, selectedQuery]);

  useEffect(() => {
    queryBuilder?.queryEditor?.setValue(selectedQuery?.query || "");
  }, [queryBuilder?.queryEditor, selectedQuery]);

  const createAccount = useCallback(async () => {
    if (!auth.user?.id) {
      return;
    }
    await auth?.supabaseClient
      .from("accounts")
      .insert({ created_by_user: auth.user.id, name: auth.user.user_metadata })
      .then((d) => {
        if (d.error) {
          throw d.error;
        }
      });
  }, [auth?.supabaseClient, auth.user]);

  const getUserAccounts = useCallback(async () => {
    return await auth?.supabaseClient
      ?.from("accounts")
      .select("id, name")
      .then((d) => {
        if (d.error) {
          throw d.error;
        }

        if (!d.data?.length && false) {
          // TODO resolve not be stupid risky
          return createAccount().then(getUserAccounts);
        }

        return d.data;
        // const selectedAccount = d.data[0];
      });
  }, [auth?.supabaseClient, createAccount]);

  const fetchQueries = useCallback(() => {
    if (!auth.supabaseClient) {
      return;
    }

    auth.supabaseClient
      .from("queries")
      .select("*")
      .order("created_at")
      .then((queriesData) => {
        setQueries(queriesData.data);
      });
  }, [auth.supabaseClient]);

  const saveQuery = useCallback(
    async (props?: { name?: string; id?: string }) => {
      const selectedAccount = await getUserAccounts()
        .then((accounts) => {
          return accounts[0];
        })
        .catch((e) => {
          console.error(e);
        });

      await auth?.supabaseClient
        .from("queries")
        .upsert({
          id: selectedQuery?.id,
          account_id: selectedAccount.id,
          query: queryBuilder.query,
          snippet: queryBuilder.codeToQuery,
          name: selectedQuery?.name || new Date().toISOString(),
          ...props,
        })
        .eq("id", selectedQuery?.id);

      fetchQueries();
    },
    [
      selectedQuery,
      auth?.supabaseClient,
      queryBuilder.codeToQuery,
      getUserAccounts,
      queryBuilder.query,
      fetchQueries,
    ]
  );
  const navigate = useNavigate();

  const newQuery = useCallback(async () => {
    const selectedAccount = await getUserAccounts()
      .then((accounts) => {
        return accounts[0];
      })
      .catch((e) => {
        console.error(e);
      });

    const newQuery = await auth?.supabaseClient
      .from("queries")
      .insert({
        account_id: selectedAccount.id,
        name: new Date().toISOString(),
      })
      .select("id");
    navigate(`esquery-editor/${newQuery.data[0].id}`);
    fetchQueries();
  }, [auth?.supabaseClient, getUserAccounts, navigate, fetchQueries]);

  const deleteQuery = useCallback(
    async (id: string) => {
      await auth?.supabaseClient
        .from("queries")
        .delete({
          count: "estimated",
        })
        .eq("id", id);
      fetchQueries();
      navigate('/esquery-editor')
    },
    [auth?.supabaseClient, fetchQueries, navigate]
  );

  useEffect(() => {
    fetchQueries();
  }, [fetchQueries]);

  return {
    saveQuery,
    deleteQuery,
    queries,
    fetchQueries,
    selectedQuery,
    newQuery,
  };
}
