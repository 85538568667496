import * as monaco from "monaco-editor";
import { useEffect } from "react";
import { IDisposable } from "monaco-editor";

export const useMonacoEvent = function <
  Event extends keyof monaco.editor.ICodeEditor & `onDid${string}`
>(
  callback: (
    ...e: Parameters<Parameters<monaco.editor.IStandaloneCodeEditor[Event]>[0]>
  ) => any,
  deps: unknown[],
  instance: monaco.editor.IStandaloneCodeEditor,
  event: Event,
) {
  useEffect(() => {
    const onDidEventHappen = instance?.[event] as unknown as any;
    if (!onDidEventHappen) {
      return;
    }
    const disposable: IDisposable = onDidEventHappen(callback);

    return () => {
      disposable.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, instance]);
};
