import * as React from "react";

export const List: React.FC<
  React.PropsWithChildren & JSX.IntrinsicElements["ul"]
> = ({ children, ...props }) => (
  <ul
    className="divide-y divide-gray-200 dark:text-slate-300 text-lg relative overflow-visible z-30"
    {...props}
  >
    {children}
  </ul>
);
export const ListItem: React.FC<
  React.PropsWithChildren & JSX.IntrinsicElements["li"]
> = ({ children, ...props }) => (
  <li className="flex pr-2 items-center overflow-auto" {...props}>
    {children}
  </li>
);
export const ListIcons: React.FC<
  React.PropsWithChildren & JSX.IntrinsicElements["div"]
> = ({ children, ...props }) => (
  <div className={"flex sticky left-0 bg-zinc-900"} {...props}>
    {children}
  </div>
);
export const LIST_ICONS_CLASS = "box-content p-2 w-4 cursor-pointer";
