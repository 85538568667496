import { useApp } from "./app-context";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import * as React from "react";

export function Loader() {
  const { isAuthLoading } = useApp();
  return (
    <>
      {isAuthLoading && (
        <div
          className={"fixed w-screen h-screen flex items-center justify-center"}
          style={{ zIndex: 999999 }}
        >
          <div
            className={"fixed w-screen h-screen bg-zinc-900 opacity-50 z-10"}
          />
          <AiOutlineLoading3Quarters
            className={"relative dark:text-white w-12 h-12 z-20 animate-spin"}
          />
        </div>
      )}
    </>
  );
}
