import { useCallback, useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { createClient, User } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

export function useAuth() {
  const navigate = useNavigate();
  const esqueryRouteMatch = useMatch("esquery-editor/*");
  const authRouteMatch = useMatch("auth");
  const isDemo = useMatch("demo");
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User>();

  const supabaseClient = useMemo(
    () => createClient(supabaseUrl, supabaseKey),
    []
  );

  useEffect(() => {
    if (!!isDemo) {
      return;
    }
    const initSupabase = async () => {
      const validateUser = async () => {
        setIsAuthLoading(false);
        const userResponse = await supabaseClient.auth.getUser();
        const localUser = userResponse?.data?.user;
        setUser(localUser);
        if (!localUser) {
          navigate("/");
          return;
        }
        if (authRouteMatch) {
          return;
        }
        if (!esqueryRouteMatch) {
          navigate("/esquery-editor");
        }
      };

      if (process.env.REACT_APP_IGNORE_AUTH) {
        return;
      }
      setIsAuthLoading(true);

      // TODO Make sure old requests are aborted
      supabaseClient.auth.onAuthStateChange(validateUser);
      validateUser().catch((e) => {
        console.error(e);
      });
    };
    initSupabase().catch((e) => {
      console.error(e);
    });
  }, [navigate, isDemo, supabaseClient, esqueryRouteMatch, authRouteMatch]);

  const authUsingGithub = useCallback(async () => {
    setIsAuthLoading(true);
    await supabaseClient.auth
      .signInWithOAuth({
        provider: "github",
      })
      .catch(() => {
        setIsAuthLoading(false);
      });
  }, [supabaseClient]);

  const authUsingEmail = useCallback(async () => {
    setIsAuthLoading(true);

    await supabaseClient.auth
      .signUp({
        email: "adam@gmail.com",
        password: "unbreach!@#ASD9887able",
      })
      .catch(() => {});
    setIsAuthLoading(false);
    await supabaseClient.auth
      .signInWithPassword({
        email: "adam@gmail.com",
        password: "unbreach!@#ASD9887able",
      })
      .catch((e) => {
        console.error(e);
      });
    setIsAuthLoading(false);
  }, [supabaseClient]);

  const logout = useCallback(async () => {
    setIsAuthLoading(true);
    await supabaseClient.auth.signOut().catch();
    setIsAuthLoading(false);
  }, [supabaseClient]);

  return {
    isAuthLoading,
    setIsAuthLoading,
    user,
    setUser,
    supabaseClient,
    authUsingGithub,
    authUsingEmail,
    logout,
  };
}
