import * as React from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useApp } from "../../app-context";

export const Auth: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { supabaseClient } = useApp();

  useEffect(() => {
    const sessionId = searchParams.get("sessionId");
    if (!sessionId) {
      return;
    }

    supabaseClient.auth
      .getSession()
      .then((session) => {
        return supabaseClient
          .from("cli_auth_sessions")
          .update({
            id: sessionId,
            token: session.data.session.access_token,
            user_id: session.data.session.user.id,
          })
          .eq("id", sessionId)
          .select("*")
          .single();
      })
      .then((data) => {
        if (!data.data) {
          console.log("cant update token");
        }
        // TODO notify user
        console.log(data.data);
      })
      .catch((e) => {
        console.error(e);
        console.log("cant update token");
      });
  }, [supabaseClient, searchParams]);

  return (
    <div className={"flex items-center justify-center h-screen"}>
      <div className={"shadow-lg border rounded"}>
        <h1>Hi!</h1>
      </div>
    </div>
  );
};
