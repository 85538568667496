import * as React from "react";
import { FC } from "react";
import "./prism.css";
import { Header } from "./Header";
import { Hero } from "./Hero";

export const NotAuthedPage: FC = () => {
  return (
    <>
      <Header />
      <Hero />
    </>
  );
};
