import * as React from "react";
import { useState } from "react";
import { AppProvider } from "./app-context";
import { Outlet } from "react-router-dom";
import { useTabs } from "./app-setup-hooks/useTabs";
import { useAuth } from "./app-setup-hooks/useAuth";
import { useQueryBuilder } from "./app-setup-hooks/useQueryBuilder";
import { Loader } from "./Loader";
import { useAccountStore } from "./app-setup-hooks/useAccountStore";

export function useAppState() {
  const useQueryBuilderState = useQueryBuilder();
  const useTabsState = useTabs();
  const useAuthState = useAuth();
  const useAccountStoreState = useAccountStore({
    auth: useAuthState,
    queryBuilder: useQueryBuilderState,
  });

  const [theme, setTheme] = useState("vs-dark");

  return {
    ...useAuthState,
    ...useTabsState,
    ...useQueryBuilderState,
    ...useAccountStoreState,
    theme,
    setTheme,
  };
}

export const App: React.FC = () => {
  const state = useAppState();

  return (
    <AppProvider value={state}>
      <div>
        <Loader />
        {!state.isAuthLoading && (
          <div>
            <Outlet />
          </div>
        )}
      </div>
    </AppProvider>
  );
};
